import { CHILD_TYPE_LINK, CHILD_TYPE_LIST_ITEM, CHILD_TYPE_TEXT } from "./constants";

export function getRandomId() {
    const ObjectId = (
        m = Math,
        d = Date,
        h = 16,
        s = (s) => m.floor(s).toString(h)
    ) => s(d.now() / 1000) + " ".repeat(h).replace(/./g, () => s(m.random() * h));
    return ObjectId();
}

export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
};

export const rgbToHex = (r, g, b) => {
    return "#" + [r, g, b].map(x => {
        const hex = x.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    }).join("");
};

export const interpolateColor = (color1, color2, factor = 0.5) => {
    const color1Rgb = hexToRgb(color1);
    const color2Rgb = hexToRgb(color2);

    const result = {
        r: Math.round(color1Rgb.r + factor * (color2Rgb.r - color1Rgb.r)),
        g: Math.round(color1Rgb.g + factor * (color2Rgb.g - color1Rgb.g)),
        b: Math.round(color1Rgb.b + factor * (color2Rgb.b - color1Rgb.b))
    };

    return rgbToHex(result.r, result.g, result.b);
};

export function renderArticleChildren(children) {
    return children.map((child, idx) => {
        let childElement = null;

        // Обработка обычного текста
        if (child.type === CHILD_TYPE_TEXT) {
            childElement = (
              <span
                className={`${child.bold ? "bold" : ""}${
                  child.italic ? "italic" : ""
                }${child.underline ? "underline" : ""}${
                  child.strikethrough ? "strikethrough" : ""
                }${child.goodTitle ? "goodTitle" : ""}${
                  child.badTitle ? "badTitle" : ""
                }`}
                key={idx}
              >
                {child.text}
              </span>
            );
        }

        // Обработка ссылок
        if (child.type === CHILD_TYPE_LINK && child.children) {
            childElement = (
                <a
                    className={`link ${child.bold ? "bold" : ""}${
                    child.italic ? "italic" : ""
                    }${child.underline ? "underline" : ""}${
                    child.strikethrough ? "strikethrough" : ""
                    }`}
                    key={idx}
                    href={child.url}
                    target="_blank"
                    rel="noreferrer"
                >
                    {renderArticleChildren(child.children)}
                </a>
            );
        }

        // Обработка элементов списка
        if (child.type === CHILD_TYPE_LIST_ITEM && child.children) {
            childElement = renderArticleChildren(child.children);
        }

      return childElement;
    });
  };