import useWindowSize from '../../../assets/hooks/useWindowSize';
import { PlusPlanIcon, PlusPointIcon, StandartPlanIcon, TickKeyPointIcon } from '../../../assets/icons/price';
import './ChoosePlan.css';

import lk from '../../../assets/images/marketing/lk.webp'
const STANDART_PLAN = {
    icon: StandartPlanIcon({ mainClassName: 'choose-plan__tarrif-icon' }),
    price: {
        value: 294,
        currency: 'AED',
    },
    description: 'An&nbsp;online store with favorable conditions for sales: online payment solutions, integration with advertising tools, logistic solutions, social media, advanced analytics, and live support.',
    key_points: [
        {
            value: 'Unlimited products',
        },
        {
            value: 'Unlimited storage',
        },
        {
            value: 'Unlimited bandwidth',
        },
        {
            value: 'Online payments',
        },
        {
            value: 'No additional transaction fees',
        },
        {
            value: 'Real-time shipping rates',
        },
        {
            value: 'Design templates',
        },
        {
            value: 'Sales channels: Facebook, Instagram, Google, TikTok, WhatsApp',
        },
        {
            value: 'Products & Orders management',
        },
        {
            value: 'CRM system',
        },
        {
            value: 'Wholesale prices and discounts',
        },
        {
            value: 'Promotions and coupons',
        },
        {
            value: 'Multi-currency',
        },
        {
            value: 'Multi-language',
        },
        {
            value: 'Multi user admin panel',
        },
        {
            value: 'Real-time dashboard',
        },
        {
            value: 'Connect your own domain',
        },
        {
            value: 'Free SSL',
        },
        {
            value: 'Analytical tools',
        },
    ],
}


const PLUS_PLAN = {
    icon: PlusPlanIcon({ mainClassName: 'choose-plan__tarrif-icon choose-plan__tarrif-icon_plus' }),
    price: {
        value: 990,
        currency: 'AED',
    },
    description: 'Empower your business possibilities with a personal e-commerce specialist, custom design, and personal support manager',
    plus_points: [
        {
            value: 'Personal e-commerce specialist',
        },
        {
            value: 'Individual design of the store',
        },
        {
            value: 'Multichannel live support',
        },
    ],
}

function ChoosePlan({ onSignupOpen }) {
    const { width } = useWindowSize()
    const STANDART_PLAN_KEY_POINTS = width > 880 ? STANDART_PLAN.key_points.slice(0, 7) : STANDART_PLAN.key_points

    return (
        <div className='choose-plan'>
            <h4 className='choose-plan__title'>Choose your plan</h4>
            <div className='choose-plan__content'>
                <div className='choose-plan__tarrifs'>
                    <div className='choose-plan__tarrif'>
                        {STANDART_PLAN.icon}
                        <div className='choose-plan__tarrif-price-box'>
                            <p className='choose-plan__tarrif-price-value'>{STANDART_PLAN.price.value}</p>
                            <div className='choose-plan__tarrif-price-cur-and-period'>
                                <p className='choose-plan__tarrif-price-currency'>{STANDART_PLAN.price.currency}</p>
                                <p className='choose-plan__tarrif-price-period'>/month*</p>
                            </div>
                        </div>
                        <button className='choose-plan__tarrif-start' type='button' onClick={onSignupOpen}>
                            Start free trial
                        </button>
                        <p className='choose-plan__tarrif-description'
                            dangerouslySetInnerHTML={{
                                __html: STANDART_PLAN.description,
                            }}
                        ></p>
                        <p className='choose-plan__tarrif-hint'>*when purchased for 3&nbsp;years</p>
                        <div className='choose-plan__tarrif-key-points'>
                            {STANDART_PLAN_KEY_POINTS.map((item, i) => (
                                <div className='choose-plan__tarrif-key-point' key={`choose-plan__tarrif-key-point_standart_${i}`}>
                                    <TickKeyPointIcon mainClassName={'choose-plan__tarrif-key-point-tick'} />
                                    <p className='choose-plan__tarrif-key-point-value'>{item.value}</p>
                                </div>
                            ))}
                            <div className='choose-plan__tarrif-key-point'>
                                <TickKeyPointIcon mainClassName={'choose-plan__tarrif-key-point-tick'} />
                                <p className='choose-plan__tarrif-key-point-value choose-plan__tarrif-key-point-value_final' >All future updates are free!</p>
                            </div>
                        </div>
                        {/* <button className='choose-plan__tarrif-compare' type='button'>
                            Compare all features
                        </button> */}
                    </div>
                    <div className='choose-plan__tarrif choose-plan__tarrif_plus'>
                        {PLUS_PLAN.icon}
                        <div className='choose-plan__tarrif-price-box'>
                            <p className='choose-plan__tarrif-price-value'>{PLUS_PLAN.price.value}</p>
                            <div className='choose-plan__tarrif-price-cur-and-period'>
                                <p className='choose-plan__tarrif-price-currency'>{PLUS_PLAN.price.currency}</p>
                                <p className='choose-plan__tarrif-price-period'>/month*</p>
                            </div>
                        </div>
                        <button className='choose-plan__tarrif-start' type='button' onClick={onSignupOpen}>
                            Start free trial
                        </button>
                        <p className='choose-plan__tarrif-description'
                            dangerouslySetInnerHTML={{
                                __html: PLUS_PLAN.description,
                            }}
                        ></p>
                        <p className='choose-plan__tarrif-hint'>*when purchased for 3&nbsp;years</p>
                        <div className='choose-plan__tarrif-key-points'>
                            <p className='choose-plan__tarrif-key-points-title'>Everything in Sellavi, plus:</p>
                            {PLUS_PLAN.plus_points.map((item, i) => (
                                <div className='choose-plan__tarrif-key-point' key={`choose-plan__tarrif-plus-point_plus_${i}`}>
                                    <PlusPointIcon mainClassName={'choose-plan__tarrif-key-point-tick'} />
                                    <p className='choose-plan__tarrif-key-point-value'>{item.value}</p>
                                </div>
                            ))}
                        </div>
                        {/* <button className='choose-plan__tarrif-compare' type='button'>
                            Compare all features
                        </button> */}
                    </div>
                </div>
                <img className='choose-plan__img' src={lk} alt='' />
            </div>
        </div>
    );
}

export default ChoosePlan