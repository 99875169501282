import { useState } from "react";
import CustomInput from "../../CustomInput/CustomInput";
import Checkbox from "../../Checkbox/Checkbox";
import bg from "../../../assets/images/partners/form/bg.webp";
import tl from "../../../assets/images/partners/form/left-top-stars.webp";
import bl from "../../../assets/images/partners/form/left-bottom-stars.webp";
import tr from "../../../assets/images/partners/form/right-top-stars.webp";
import br from "../../../assets/images/partners/form/right-bottom-stars.webp";
import "./PartnersForm.css";

function PartnersForm() {
  const [values, setValues] = useState({});

  function handleChange(evt) {
    const input = evt.target;
    const name = input.name;
    const type = input.type;
    const value = type !== "checkbox" ? input.value : input.checked;
    setValues((prevVal) => ({ ...prevVal, [name]: value }));
  }

  function handleSubmit(evt) {
    evt.preventDefault();
    console.log("submit");
  }

  return (
    <div className="partners-form">
      <p className="partners-form__title">open new horizons</p>
      <p className="partners-form__subtitle">for your business and partners</p>

      <form className="partners-form__form" onSubmit={handleSubmit}>
        <div className="partners-form__container">
          <p className="partners-form__form-title">Registration form</p>
          <div className="partners-form__inputs-block">
            <CustomInput
              name="name"
              label="Full name"
              placeholder="Full name"
              value={values.name}
              onChange={handleChange}
            />
            <CustomInput
              name="email"
              label="E-mail"
              placeholder="E-mail"
              value={values.email}
              onChange={handleChange}
            />
            <CustomInput
              name="phone"
              label="Phone number"
              placeholder="Phone number"
              value={values.phone}
              onChange={handleChange}
            />
            <CustomInput
              name="business_name"
              label="Business name (optional)"
              placeholder="Business name (optional)"
              value={values.business_name}
              onChange={handleChange}
            />
            <CustomInput
              name="occupation"
              label="Occupation (optional)"
              placeholder="Occupation (optional)"
              value={values.occupation}
              onChange={handleChange}
            />
          </div>
          <Checkbox
            name="confirm"
            label={`I confirm that I have read and agree with  <a href='/#' target='_blank'>the cooperation agreement</a>`}
            checked={values.confirm}
            onChange={handleChange}
          />
          <button className="partners-form__submit-btn" type="submit">
            Become our partner
          </button>
        </div>
      </form>

      <img className="partners-form__bg" src={bg} alt="" />
      <img
        className="partners-form__stars partners-form__stars_type_top-left"
        src={tl}
        alt=""
      />
      <img
        className="partners-form__stars partners-form__stars_type_bottom-left"
        src={bl}
        alt=""
      />
      <img
        className="partners-form__stars partners-form__stars_type_top-right"
        src={tr}
        alt=""
      />
      <img
        className="partners-form__stars partners-form__stars_type_bottom-right"
        src={br}
        alt=""
      />
    </div>
  );
}

export default PartnersForm;
