import "./Partners.css";
import PartnersForm from "./PartnersForm/PartnersForm";
import PartnersHeading from "./PartnersHeading/PartnersHeading";
import PartnersOverview from "./PartnersOverview/PartnersOverview";
import PartnersPros from "./PartnersPros/PartnersPros";
import PartnersTarget from "./PartnersTarget/PartnersTarget";

function Partners() {
  return (
    <section className="partners-page">
      <PartnersHeading />
      <PartnersPros />
      <PartnersOverview />
      <PartnersTarget />
      <PartnersForm />
    </section>
  );
}

export default Partners;
