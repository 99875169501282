import { useEffect, useState } from "react";
import "./Article.css";
import { useParams } from "react-router-dom";
import { Provider, LikeButton } from "@lyket/react";
import mainApi from "../../../assets/api/MainApi";
import like from "../../../assets/images/article/like-icon.png";
import insta from "../../../assets/images/article/insta-icon.png";
import twitter from "../../../assets/images/article/twitter-icon.png";
import facebook from "../../../assets/images/article/facebook-icon.png";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import ImageElement from "./ImageElement/ImageElement";
import {
  CODE_BLOCK,
  HEADING_BLOCK,
  IMAGE_BLOCK,
  LIST_BLOCK,
  PARAGRAPH_BLOCK,
  QUOTE_BLOCK,
  apiKeyForLikes,
} from "../../../assets/utils/constants";
import TextElement from "./TextElement/TextElement";
import ListElement from "./ListElement/ListElement";
import QuoteElement from "./QuoteElement/QuoteElement";
import SubscribeForm from "../SubscribeForm/SubscribeForm";
import TextElementWithVariables from "./TextElementWithVariables/TextElementWithVariables";
import ContentNav from "./ContentNav/ContentNav";

const socialIcons = [insta, twitter, facebook];

function Article({ onSignupOpen }) {
  const { translit_name } = useParams();
  const [articleObj, setArticleObj] = useState(undefined);
  const [isPreloaderVisible, setIsPrelouderVisible] = useState(true);

  useEffect(() => {
    if (!translit_name) return;

    setIsPrelouderVisible(true);
    mainApi
      .getArticle({ translit_name })
      .then((res) => {
        setArticleObj(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsPrelouderVisible(false);
      });
  }, [translit_name]);

  return (
    <section className="article">
      <div className="article__container">
        {!isPreloaderVisible ? (
          <article className="article__content">
            <h1 className="article__title">{articleObj.title}</h1>
            <div className="article__box">
              <ul className="article__side-likes">
                <li className="article__like-item">
                  <div
                    className="article__like-btn"
                    onClick={(evt) => evt.stopPropagation()}
                  >
                    <Provider apiKey={apiKeyForLikes} disableSessionId={true}>
                      <LikeButton
                        namespace={`like-card-namespace-${articleObj.id}`}
                        id={`like-card-id-${articleObj.id}`}
                        component={LikeButton.templates.Twitter}
                      >
                        {({ handlePress, totalLikes, isLoading }) => (
                          <button
                            className="article__like-btn-box"
                            onClick={handlePress}
                            disabled={isLoading}
                            type="button"
                          >
                            <img
                              className="article__like-icon"
                              src={like}
                              alt=""
                            />
                            <p className="article__like-text">
                              {totalLikes + Number(articleObj.likes_count)}
                            </p>
                          </button>
                        )}
                      </LikeButton>
                    </Provider>
                  </div>
                </li>

                {articleObj.social_likes.length > 0
                  ? articleObj.social_likes.map((item, i) => (
                    <li className="article__like-item" key={item.id}>
                      <a
                        className="article__like-btn"
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="article__like-icon"
                          src={socialIcons[i]}
                          alt=""
                        />
                        <p className="article__like-text">{item.count}</p>
                      </a>
                    </li>
                  ))
                  : null}
              </ul>

              <div className="article__block">
                <ImageElement data={articleObj.banner_img} />
                <ContentNav article={articleObj.post} />

                {articleObj.post.map((item, i) =>
                  item.type === HEADING_BLOCK ||
                    item.type === PARAGRAPH_BLOCK ? (
                    <TextElement
                      data={item}
                      index={i}
                      key={`${item.type}-${i}`}
                      {...{ onSignupOpen }}
                    />
                  ) : item.type === LIST_BLOCK ? (
                    <ListElement data={item} key={`${item.type}-${i}`} />
                  ) : item.type === IMAGE_BLOCK ? (
                    <ImageElement data={item.image} key={`${item.type}-${i}`} />
                  ) : item.type === QUOTE_BLOCK ? (
                    <QuoteElement
                      data={item.children}
                      key={`${item.type}-${i}`}
                    />
                  ) : item.type === CODE_BLOCK ? (
                    <TextElementWithVariables
                      data={item.children}
                      key={`${item.type}-${i}`}
                    />
                  ) : null
                )}
              </div>
            </div>
          </article>
        ) : (
          <MiniPreloader />
        )}
      </div>

      <div className="article__divider-block">
        <p className="article__divider-text">
          If you have any questions, our qualified support team is always happy
          to help.
        </p>
      </div>

      <div className="article__closure-block">
        {!isPreloaderVisible ? (
          <div className="article__likes-block">
            <p className="article__likes-title">Like?</p>
            <div
              className="article__like-btn article__like-btn_column"
              onClick={(evt) => evt.stopPropagation()}
            >
              <Provider apiKey={apiKeyForLikes} disableSessionId={true}>
                <LikeButton
                  namespace={`like-card-namespace-${articleObj.id}`}
                  id={`like-card-id-${articleObj.id}`}
                  component={LikeButton.templates.Twitter}
                >
                  {({ handlePress, totalLikes, isLoading }) => (
                    <button
                      className="article__like-btn-box article__like-btn-box_column"
                      onClick={handlePress}
                      disabled={isLoading}
                      type="button"
                    >
                      <img
                        className="article__like-icon article__like-icon_type_small"
                        src={like}
                        alt=""
                      />
                      <p className="article__like-text">
                        {totalLikes + Number(articleObj.likes_count)}
                      </p>
                    </button>
                  )}
                </LikeButton>
              </Provider>
            </div>

            {articleObj.social_likes.length > 0 ? (
              <div className="article__social-block">
                <p className="article__likes-title">Share in social media</p>
                <ul className="article__social-list">
                  {articleObj.social_likes.map((item, i) => (
                    <li className="article__social-item" key={item.id}>
                      <a
                        className="article__like-btn article__like-btn_column"
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="article__like-icon article__like-icon_type_small"
                          src={socialIcons[i]}
                          alt=""
                        />
                        <p className="article__like-text">{item.count}</p>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        ) : (
          <MiniPreloader />
        )}

        <div className="article__form-block">
          <SubscribeForm />
        </div>
      </div>
    </section>
  );
}

export default Article;
