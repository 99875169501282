import { useEffect, useRef } from "react";
import "./InputSelect.css";
import { DropdownRectangle } from "../../../assets/icons/icons";

function InputSelect({
  name,
  value,
  selectList,
  onChange,
  onOpenDropdown,
  onCloseDropdown,
  isOpen,
}) {
  const overlay = useRef();

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        onCloseDropdown();
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  function handleSelect(item) {
    onChange({
      target: {
        value: item,
        name,
      },
    });
    onCloseDropdown();
  }

  return (
    <div className="select">
      <button
        className="select__value-box"
        type="button"
        onClick={onOpenDropdown}
      >
        {value?.icon({
          mainClassName: "select__value-icon",
        })}
        <p className="select__value">{value?.value ? value.value : ""}</p>
      </button>

      <div
        className={`select__dropdown ${
          isOpen ? "select__dropdown_opened" : ""
        }`}
      >
        <div className="select__dropdown-container">
          <DropdownRectangle
            mainClassName="select__dropdown-arrow"
            fillClassName="select__dropdown-arrow-fill"
            strokeClassName="select__dropdown-arrow-stroke"
          />
          <ul className="select__list">
            {selectList.map((item) => (
              <li className="select__item" key={item.value}>
                <button
                  className={`select__item-btn ${
                    item.value === value?.value
                      ? "select__item-btn_selected"
                      : ""
                  }`}
                  type="button"
                  onClick={() => handleSelect(item)}
                >
                  {item?.icon({ mainClassName: "select__item-icon" })}
                  <p className="select__item-text">{item.value}</p>
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="select__dropdown-overlay" ref={overlay} />
      </div>
    </div>
  );
}

export default InputSelect;
