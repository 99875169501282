import { createRef, useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Mousewheel, EffectCoverflow } from "swiper/modules";
import { motion, useAnimation, useInView } from 'framer-motion';

import slide_1 from '../../../assets/images/head-banner/screen-1.webp'
import slide_2 from '../../../assets/images/head-banner/screen-2.webp'
import slide_3 from '../../../assets/images/head-banner/screen-3.webp'
import slide_4 from '../../../assets/images/head-banner/screen-4.webp'
import slide_5 from '../../../assets/images/head-banner/screen-5.webp'
import slide_6 from '../../../assets/images/head-banner/screen-6.webp'
import slide_7 from '../../../assets/images/head-banner/screen-7.webp'
import slide_8 from '../../../assets/images/head-banner/screen-8.webp'
import slide_9 from '../../../assets/images/head-banner/screen-9.webp'
import slide_10 from '../../../assets/images/head-banner/screen-10.webp'
import slide_11 from '../../../assets/images/head-banner/screen-11.webp'
import slide_12 from '../../../assets/images/head-banner/screen-12.webp'
import slide_13 from '../../../assets/images/head-banner/screen-13.webp'

// import { motion } from 'framer-motion'

import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/effect-coverflow';

import './HeadBanner.css';
import { ArrowIcon } from '../../../assets/icons/icons';

function HeadBanner({ onSignupOpen, onEmailChange, email, emailValidity }) {
  // const [value, setValue] = useState('')
  const [my_swiper, set_my_swiper] = useState({});

  const handleSingupOpen = async (evt) => {
    evt.preventDefault();

    // TODO: uncomment valid
    // const botToken = '6671687003:AAG-keX0Lch_FC_lduj4fzC6JBcyEOgL0K'; // WARNING: Exposing token
    const botToken = '6671687003:AAG-keX0Lch_FC_lduj4fzC6JBcyEOgL0KU'; // WARNING: Exposing token

    const chatId = '-1001990371125';
    const text = `________
Fast Lead Capture
    
Locale: UAE
Email: ${email}
________`;

    try {
      const response = await fetch(`https://api.telegram.org/bot${botToken}/sendMessage?chat_id=${chatId}&text=${encodeURIComponent(text)}`);
      const data = await response.json();
      console.log(data);
      // Handle success (e.g., clear form, show success message)
    } catch (error) {
      console.error('Error:', error);
      // Handle error
    }
    onSignupOpen();
    // window.location.href = `${LK_LINK}/${LK_LINK_AUTH}/${LK_LINK_SIGNUP}?phone=${form.phone.replace(/\D/g, "")}`

  };

  // function handleChange(e) {
  //     const input = e.target;
  //     const name = input.name;
  //     const value = input.value;
  //     setValue(value)
  // }

  // const settings = {
  //     effect: "coverflow",
  //     centeredSlides: true,
  //     slidesPerView: 2,
  //     coverflowEffect: {
  //         rotate: 0, // Slide rotate in degrees
  //         stretch: 40, // Stretch space between slides (in px)
  //         depth: 300, // Depth offset in px (slides translate in Z axis)
  //         modifier: 1, // Effect multipler
  //         slideShadows: false // Enables slides shadows
  //     }
  // };

  const titleText = "Your online store is 5 minutes away!";
  const titleLetters = Array.from(titleText);

  // const titleVariants = {
  //     hidden: { opacity: 0, y: -20 },
  //     visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  // };

  // const swiperVariants = {
  //     hidden: { opacity: 0 },
  //     visible: { opacity: 1, transition: { delay: 0.5 } },
  // };

  const letterVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: i => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.03, // Adjust the delay for stagger effect
      },
    }),
  };

  const titleRef = useRef(null);
  const swiperRef = useRef(null);
  const titleControls = useAnimation();
  const swiperControls = useAnimation();

  // Detect when the title is in view
  const titleInView = useInView(titleRef, { once: true });
  const swiperInView = useInView(swiperRef, { once: true });

  useEffect(() => {
    if (titleInView) {
      titleControls.start('visible');
    }
    if (swiperInView) {
      swiperControls.start('visible');
    }
  }, [titleInView, swiperInView, titleControls, swiperControls]);

  const slidesArr = [slide_1, slide_2, slide_3, slide_4, slide_5, slide_6, slide_7, slide_8, slide_9, slide_10, slide_11, slide_12, slide_13];
  const slides = [...slidesArr, ...slidesArr, ...slidesArr];
  const centeredSlideIndex = Math.floor(slides.length / 2); // index of the slide you want to center initially
  const centeredSlideMobileIndex = Math.floor(slidesArr.length / 2);;
  const controls = useAnimation();

  const slideVariants = index => ({
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        delay: 0.3 * Math.abs(index - centeredSlideIndex), // Adjust the delay for each slide
        duration: 0.5 // Duration of the opacity transition
      }
    }
  });

  const slideVariantsMobile = index => ({
    initial: { opacity: 0 },
    animate: {
      opacity: 1,
      transition: {
        delay: 0.3 * Math.abs(index - centeredSlideMobileIndex), // Adjust the delay for each slide
        duration: 0.5 // Duration of the opacity transition
      }
    }
  });

  useEffect(() => {
    controls.start('animate');
  }, [controls]);

  // function validateEmail(email) {
  //     const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //     return regexp.test(email);
  // }
  const [focused, setFocused] = useState(true)


  const ref = createRef(null);

  const handleMouseOut = (currentRef) => {

    setFocused(false)


  };

  const handleMouseOn = (currentRef) => {
    if (document.activeElement === currentRef) {
      setFocused(true)
    }

  };

  return (
    <div className='head-banner' id='start' data-section>
      <div className='head-banner__main'>
        <motion.h2
          className='head-banner__title'
          ref={titleRef}
          initial="hidden"
          animate={titleControls}
        >
          {titleLetters.map((letter, i) => (
            <motion.span key={i} custom={i} variants={letterVariants}>
              {letter}
            </motion.span>
          ))}
        </motion.h2>
        <div className={`head-banner__input-box ${email ? 'head-banner__input-box_inactive' : ''} ${emailValidity?.validState ? 'head-banner__input-box_valid' : ''} ${!focused && !emailValidity?.validState && email ? 'head-banner__input-box_not-valid' : ''}`}>
          <input
            ref={ref}
            onMouseEnter={() => handleMouseOn(ref.current)}
            onFocus={() => handleMouseOn(ref.current)}
            onBlur={() => handleMouseOut(ref.current)}
            className="head-banner__input"
            name={'email'}
            type={'text'}
            inputMode={'text'}
            value={email || ""}
            placeholder='Email address'
            onChange={onEmailChange}
          />
          <button className={`head-banner__button head-banner__button_pc ${!emailValidity?.validState && email ? 'head-banner__button_inactive' : ''} ${!email && !emailValidity.validState ? 'head-banner__button_diasbled' : ''}`} type='button' onClick={handleSingupOpen}>
            Start free trial
          </button>
        </div>
        <button className={`head-banner__button head-banner__button_mobile ${!emailValidity?.validState && email ? 'head-banner__button_inactive' : ''} ${!email && !emailValidity.validState ? 'head-banner__button_diasbled' : ''}`} type='button' onClick={handleSingupOpen}>
          Start free trial
        </button>
      </div>

      <div className='head-banner__slides-swiper'>
        <div className='head-banner__gradient' />
        <Swiper
          // slidesPerView={1}
          loop={true}
          initialSlide={centeredSlideIndex}
          speed={500}
          autoplay={{
            delay: 3000,
          }}
          //spaceBetween={-5}
          pagination={{
            clickable: true,

          }}
          mousewheel={{
            forceToAxis: true,
          }}
          effect={"coverflow"}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 500,
            modifier: 1,
            slideShadows: false,
          }}
          breakpoints={{
            // when window width is >= 320px 
            600: {
              coverflowEffect: {
                rotate: 0,
                stretch: 5,
                depth: 150,
                modifier: 1,
                slideShadows: false,
              }
            },
          }}
          // coverflowEffect={{
          //     rotate: 50,
          //     stretch: 0,
          //     depth: 100,
          //     modifier: 1,
          //     slideShadows: true,
          // }}
          modules={[Mousewheel, Pagination, EffectCoverflow]}
          preventInteractionOnTransition={true}
          className="head-banner__slides"
          onInit={(ev) => {
            set_my_swiper(ev)
          }}
        // onSlideChange={onSlideChange}
        // settings={settings}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={`head-banner__slide_${index}`} className={`head-banner__slide head-banner__slide_${index}`}>
              <motion.div
                custom={index}
                initial="initial"
                animate={controls}
                variants={slideVariants(index)}
              >
                <img className='head-banner__slide-img' src={slide} alt='' />
              </motion.div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className='head-banner__gradient head-banner__gradient_type_right' />
      </div>

      <div className='head-banner__slides-swiper head-banner__slides-swiper_mobile'>
        <Swiper
          loop={true}
          initialSlide={centeredSlideMobileIndex}
          speed={500}
          autoplay={{
            delay: 3000,
          }}
          pagination={{
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: '3',
          }}
          mousewheel={{
            forceToAxis: true,
          }}
          effect={"coverflow"}
          centeredSlides={true}
          slidesPerView={'auto'}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 500,
            modifier: 1,
            slideShadows: false,
          }}
          breakpoints={{
            // when window width is >= 320px 
            600: {
              coverflowEffect: {
                rotate: 0,
                stretch: 5,
                depth: 200,
                modifier: 1,
                slideShadows: false,
              }
            },
          }}
          modules={[Mousewheel, Pagination, EffectCoverflow]}
          preventInteractionOnTransition={true}
          className="head-banner__slides"
        >
          {slidesArr.map((slide, index) => (
            <SwiperSlide key={`head-banner__slide-mobile_${index}`} className={`head-banner__slide head-banner__slide_${index}`}>
              <motion.div
                custom={index}
                initial="initial"
                animate={controls}
                variants={slideVariantsMobile(index)}
              >
                <img className='head-banner__slide-img' src={slide} alt='' />
              </motion.div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className='head-banner__swiper-controls'>
        <button className='head-banner__swiper-control head-banner__swiper-control_back' type='button' onClick={() => {
          if (my_swiper) {
            my_swiper.slidePrev();
          }
        }}>
          <ArrowIcon
            mainClassName={'head-banner__swiper-control-icon'}
            strokeClassName={'head-banner__swiper-control-icon-stroke'}
          />
        </button>
        <button className='head-banner__swiper-control head-banner__swiper-control_next' type='button' onClick={() => {
          if (my_swiper) {
            my_swiper.slideNext();
          }
        }}>
          <ArrowIcon
            mainClassName={'head-banner__swiper-control-icon'}
            strokeClassName={'head-banner__swiper-control-icon-stroke head-banner__swiper-control-icon-stroke_next'}
          />
        </button>
      </div>
    </div >
  );
}

export default HeadBanner